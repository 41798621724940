<template>
    <Button
        @click="bookDemo"
        :variant="variant"
        as="a"
        class="cursor-pointer"
        title="Schedule a demo call"
    >
        <MdiIcon :icon="mdiCalendarClock" class="size-6 mr-1"></MdiIcon>

        <slot name="default">
            Or book a demo
        </slot>
    </Button>
</template>
<script setup lang="ts">
import {mdiCalendarClock} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";
import {Button} from "@/Components/ui/button";

const bookDemo = () => {
    window.open('https://cal.com/team/greetmate-inc/30-minute-demo-call')
}

defineProps(['variant'])
</script>
